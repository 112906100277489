$scrollbar-width: 6px;
$scrollbar-background-color: rgba(100, 100, 100, 0.5);
$scrollbar-background-color-dark: rgba(0, 0, 0, 0.5);
$sidenav-width: 230px;
$sidenav-collapsed-width: 65px;

$dashboard-background-color-light: #fff;
$dashboard-panel-header-color-light: #36393a;
$dashboard-panel-header-color-dark: #36393a;

$light-theme-hover-color: #eee;
$portal-breadcrumbs-light-theme: rgba(54, 57, 58, 0.6);
$portal-breadcrumbs-dark-theme: rgba(100, 100, 100, 0.6);

// reskin variables
$enthus-power-purple: #9fb4ff;
$enthus-smart-yellow: #dbff00;
$enthus-error: #f62356;
$enthus-warn: #ff7f48;
$enthus-info: #349ed9;
$enthus-success: #61ffa0;

$white-background: #ffffff;
$blue-background: #1a2753;

$card-background-color-light: #f8f8f8;
$card-background-color-dark: #263567;
$scrollbar-thumb: $enthus-power-purple;

$link-color-light: #2617db;
$link-color-dark: $enthus-smart-yellow;

$white-input-background: #ffffff;
$grey-input-background: #f8f8f8;
$blue-input-background-dark: #303c62;

$grey-sub-text-color: #858585;
$blue-sub-text-color: #9aa0b3;

$optional-inactive-button-background-light: #d9dbe2;
$optional-inactive-button-background-dark: #485275;

$hover-primary-button-background-light: #6075b6;
$hover-accent-button-background-light: #d5deff;
$hover-primary-button-background-dark: #6f84cd;
$hover-accent-button-background-dark: #6075b6;

$focus-button-color: #94f2ff;

$disabled-primary-button-background-light: #f2f3f5;
$disabled-accent-button-background-light: #ffffff;
$disabled-button-color-light: #d9dbe2;
$disabled-primary-button-background-dark: #303c62;
$disabled-accent-button-background-dark: #1a2753;
$disabled-button-color-dark: #000f40;

$light-text: #ffffff;
$dark-text: #000f40;

$mat-warn: (
  main: $enthus-error,
  lighter: #fcbdcc,
  darker: #f2153c,
  200: $enthus-error,
  // For slide toggle,
  contrast: (
      main: $light-text,
      lighter: $dark-text,
      darker: $light-text,
    ),
);

$mat-accent: (
  main: #349ed9,
  lighter: #c2e2f4,
  darker: #2182ca,
  200: #349ed9,
  // For slide toggle,
  contrast: (
      main: $dark-text,
      lighter: $dark-text,
      darker: $light-text,
    ),
);

//region LIGHT MODE
$dark-disabled-text: rgba($dark-text, 0.38);
$dark-dividers: #d9dbe2;
$dark-focused: rgba($dark-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $grey-sub-text-color,
  hint-text: $dark-disabled-text,
  accent-text: $link-color-light,
  icon: $dark-text,
  icons: $dark-text,
  text: $dark-text,
  slider-min: $dark-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-disabled-text: rgba($light-text, 0.5);
$light-dividers: #000f40;
$light-focused: rgba($light-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $grey-sub-text-color,
  accent-text: $link-color-dark,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

$document-position-quantity-input-color-light: (
  default: #ffffff,
  accessory: #ffffff,
  cart: #ffffff,
  mobile: #ffffff,
);

// Background Elements

// Light bg
$light-background: #d9dbe2;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: #858585;
$dark-bg-alpha-4: rgba(#000f40, 0.04);
$dark-bg-alpha-12: rgba(#000f40, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $card-background-color-light,
  dialog: #ffffff,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-background,
  disabled-list-option: $light-bg-darker-10,
);

$mat-light-alternative-accent: (
  main: #ffffff,
  lighter: #ffffff,
  darker: #ffffff,
  200: #ffffff,
  // For slide toggle,
  contrast: (
      main: $dark-text,
      lighter: $dark-text,
      darker: $dark-text,
    ),
);

$mat-light-primary: (
  main: #000f40,
  lighter: #b3b7c6,
  darker: #00082a,
  200: #000f40,
  // For slide toggle,
  contrast: (
      main: $light-text,
      lighter: $dark-text,
      darker: $light-text,
    ),
);

$credits-light: (
  header: $card-background-color-light,
  content: $white-background,
);
//endregion

//region DARK MODE
$dark-background: #000f40;
$dark-theme-hover-color: transparentize($dark-background, 0.8);
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#d9dbe2, 0.04);
$light-bg-alpha-12: rgba(#d9dbe2, 0.12);

$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $card-background-color-dark,
  dialog: #1a2753,
  tooltip: $dark-bg-tooltip,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: darken($card-background-color-dark, 10%),
  disabled-list-option: $dark-bg-lighter-10,
);

$mat-dark-primary: (
  main: #ffffff,
  lighter: #ffffff,
  darker: #ffffff,
  200: #ffffff,
  // For slide toggle,
  contrast: (
      main: $dark-text,
      lighter: $dark-text,
      darker: $dark-text,
    ),
);

$mat-dark-alternative-accent: (
  main: #000f40,
  lighter: #000f40,
  darker: #000f40,
  200: #000f40,
  // For slide toggle,
  contrast: (
      main: $light-text,
      lighter: $light-text,
      darker: $light-text,
    ),
);

$document-position-quantity-input-color-dark: (
  default: #010a2b,
  accessory: #010a2b,
  cart: #010a2b,
  mobile: #010a2b,
);

$credits-dark: (
  header: $card-background-color-dark,
  content: $blue-background,
);
//endregion
