@use 'sass:color';
@use 'sass:map';
@use 'variables';

portal-root {
  pre.is-optional-inactive {
    color: variables.$dark-disabled-text !important;
  }

  .mat-mdc-card,
  .mat-mdc-list-item,
  .child-position-header {
    &.is-optional {
      &-inactive {
        img:not(.info-arrow) {
          opacity: 0.2;
        }
      }
    }
  }

  &:not(.dark-theme) {
    .mat-mdc-card,
    .mat-mdc-list-item,
    .child-position-header {
      &.is-optional {
        &-inactive {
          & *:not(.dont-show-again):not(.optional-toggle .help-box .info-text) {
            color: variables.$dark-disabled-text !important;
          }

          & *.mat-mdc-button-base {
            background-color: variables.$optional-inactive-button-background-light !important;
          }
        }
      }
    }
  }

  &.dark-theme {
    pre.is-optional-inactive {
      color: variables.$light-disabled-text !important;
    }

    .child-position-list pre {
      border-image: linear-gradient(to bottom, white 60%, transparent 90%) 1 100% !important;
    }

    .mat-mdc-card,
    .mat-mdc-list-item,
    .child-position-header {
      &.is-optional {
        &-inactive {
          & mat-button-toggle * {
            color: variables.$light-disabled-text !important;
          }

          & .mat-mdc-button-base {
            background-color: variables.$optional-inactive-button-background-dark !important;
          }

          & *:not(.dont-show-again):not(.optional-toggle .help-box .info-text):not(.mat-mdc-button-base *):not(mat-button-toggle *) {
            color: variables.$light-disabled-text !important;

            .mat-mdc-button {
              border-color: variables.$light-disabled-text !important;
            }
          }
        }
      }
    }
  }
}
