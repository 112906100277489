@use 'sass:map';
@use 'variables';

.mdc-card {
  --mdc-elevated-card-container-color: #{map.get(variables.$mat-light-theme-background, card)};

  .mat-mdc-card-header,
  .mat-mdc-card-content,
  .mat-mdc-card-content:last-child {
    padding: unset;
  }

  .mat-mdc-card-content:has(.tableContainer),
  th.mat-mdc-table-sticky,
  .tableContainer {
    background: variables.$white-background;
  }
}

.dark-theme .mdc-card {
  --mdc-elevated-card-container-color: #{map.get(variables.$mat-dark-theme-background, card)} !important;

  .mat-mdc-card-content:has(.tableContainer),
  th.mat-mdc-table-sticky,
  .tableContainer {
    background: variables.$blue-background;
  }
}
