@use 'variables';
@use 'mixins';
@use 'node_modules/@angular/material' as mat;

@mixin get-slide-toggle-colors($type, $base-color, $lighten-or-darken) {
  .mat-mdc-slide-toggle.#{$type} {
    --mdc-switch-selected-focus-state-layer-color: #{$base-color};
    --mdc-switch-selected-handle-color: #{$base-color};
    --mdc-switch-selected-hover-state-layer-color: #{$base-color};
    --mdc-switch-selected-pressed-state-layer-color: #{$base-color};
    --mdc-switch-selected-focus-handle-color: #{$base-color};
    --mdc-switch-selected-hover-handle-color: #{$base-color};
    --mdc-switch-selected-pressed-handle-color: #{$base-color};

    @if $lighten-or-darken == lighten {
      --mdc-switch-selected-focus-track-color: #{transparentize($base-color, 0.8)};
      --mdc-switch-selected-hover-track-color: #{transparentize($base-color, 0.8)};
      --mdc-switch-selected-pressed-track-color: #{transparentize($base-color, 0.8)};
      --mdc-switch-selected-track-color: #{transparentize($base-color, 0.6)};
    } @else {
      --mdc-switch-selected-focus-track-color: #{transparentize($base-color, 0.4)};
      --mdc-switch-selected-hover-track-color: #{transparentize($base-color, 0.4)};
      --mdc-switch-selected-pressed-track-color: #{transparentize($base-color, 0.4)};
      --mdc-switch-selected-track-color: #{transparentize($base-color, 0.6)};
    }
  }
}

@include get-slide-toggle-colors(mat-primary, variables.$dark-background, lighten);
@include get-slide-toggle-colors(mat-accent, mat.m2-get-color-from-palette(variables.$mat-accent, main), lighten);
@include get-slide-toggle-colors(mat-warn, mat.m2-get-color-from-palette(variables.$mat-warn, main), lighten);

.mat-mdc-slide-toggle,
.dark-theme .mat-mdc-slide-toggle {
  --mdc-switch-selected-icon-color: #{variables.$light-text};
}

.dark-theme {
  @include get-slide-toggle-colors(mat-primary, variables.$white-background, darken);
  @include get-slide-toggle-colors(mat-accent, mat.m2-get-color-from-palette(variables.$mat-accent, main), darken);
  @include get-slide-toggle-colors(mat-warn, mat.m2-get-color-from-palette(variables.$mat-warn, main), darken);

  .mat-mdc-slide-toggle.mat-primary {
    --mdc-switch-selected-icon-color: #{variables.$dark-text};
  }
}
