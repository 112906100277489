@use 'sass:map';

@mixin color($theme-or-config) {
  $header-background: map.get($theme-or-config, 'header');
  $content-background: map.get($theme-or-config, 'content');
  $no-credits-placeholder-color: map.get($theme-or-config, 'no-credits-placeholder');

  portal-credits {
    .credits-wrapper {
      .credits-header {
        .credits-info-card {
          background-color: $content-background;

          .available-credits {
            background-color: $header-background;
          }

          .credits-info {
            .credits-info-content {
              mat-expansion-panel {
                background-color: $content-background !important;

                .mat-expansion-panel-content {
                  .mat-expansion-panel-body .row:nth-child(even) {
                    background-color: $header-background !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .no-open-credits-placeholder {
      color: $no-credits-placeholder-color;
    }
  }
}
