@use 'variables';
@forward 'styles/overwrites/material/card';
@forward 'styles/overwrites/material/dialog';
@forward 'styles/overwrites/material/selection-list';
@forward 'styles/overwrites/material/list';
@forward 'styles/overwrites/material/form-field';
@forward 'styles/overwrites/material/button';
@forward 'styles/overwrites/material/tab';
@forward 'styles/overwrites/material/checkbox';
@forward 'styles/overwrites/material/sidenav';
@forward 'styles/overwrites/material/datepicker';
@forward 'styles/overwrites/material/menu';
@forward 'styles/overwrites/material/expansion-panel';
@forward 'styles/overwrites/material/chip-grid';
@forward 'styles/overwrites/material/switch';
@forward 'styles/overwrites/material/bottom-sheet';
@forward 'styles/overwrites/material/badge';
@forward 'styles/overwrites/material/select';
@forward 'styles/overwrites/material/autocomplete';

.mat-mdc-card,
.mat-mdc-form-field,
button:not([mat-fab]):not([mat-mini-fab]),
.mat-dialog-container * {
  box-shadow: unset !important;
}

.main-container > :not(portal-login, portal-registration-page, portal-password-recovery, portal-new-password),
.cdk-overlay-container .cdk-overlay-pane > .mat-mdc-dialog-container > :not(portal-login-dialog) {
  .ng-dirty:not(.ng-invalid, .mat-form-field-disabled, .purchase-input-form-field, .mat-mdc-list, .hide-form-changes) {
    .mat-form-field-appearance-outline,
    .mat-form-field-appearance-fill,
    .mat-form-field-outline,
    .mat-form-field-fill,
    .mat-mdc-form-field-infix mat-label,
    .mat-mdc-select-arrow,
    .mat-mdc-floating-label {
      color: variables.$enthus-warn !important;
    }

    .mdc-line-ripple {
      &::before,
      &::after {
        border-bottom-color: variables.$enthus-warn !important;
      }
    }

    .mdc-checkbox__background {
      background-color: variables.$enthus-warn !important;
      border-color: variables.$enthus-warn !important;
    }
  }
}

.mdc-card,
.mdc-switch__track,
.mdc-switch__handle,
mat-bottom-sheet-container,
.mat-mdc-text-field-wrapper,
.mat-mdc-button-base,
.mat-mdc-menu-trigger,
.mat-button-toggle-group,
.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple,
.mdc-checkbox__background {
  border-radius: 0 !important;
}

.mat-menu-panel {
  max-width: 500px !important;
  border-radius: unset !important;

  .mat-menu-content {
    padding: unset !important;

    mat-selection-list {
      padding: unset;
    }
  }
}

.mat-drawer-container {
  background-color: unset !important;
}

:root {
  --mat-menu-container-color: #{variables.$card-background-color-light};
}

.dark-theme {
  --mat-menu-container-color: #{variables.$card-background-color-dark};
}
