@use 'variables';

@mixin no-text-selection {
  &:not(input) {
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
}

@mixin no-focus-style {
  &:focus {
    outline: none;
  }
}

@mixin bold {
  font-family: 'Circular Bold', serif !important;
}

@mixin no-tap-highlight {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

@mixin horizontal-gradient($start-color, $end-color) {
  background-image: linear-gradient(to right, $start-color 0%, $start-color 50%, $end-color 50%, $end-color 100%);
}

@mixin rotate($degrees) {
  transform: rotate($degrees);
}

@mixin position-center() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@mixin hide-number-input-arrows {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin suffix-button {
  ::ng-deep .mat-form-field-flex {
    // TODO: Check class for suffix
    .mat-form-field-suffix {
      display: inline-flex;
      justify-content: flex-end;
      flex: 1 1 auto;
      right: -12px;
      align-self: center;
      height: 47px;
      width: 40px;

      button {
        height: 59px;
        top: -12px;
      }
    }
  }
}

@mixin prefix-button {
  ::ng-deep .mat-mdc-form-field-flex {
    // TODO: Check class for prefix
    .mat-mdc-form-field-prefix {
      display: inline-flex;
      justify-content: flex-start;
      left: -12px;
      align-self: center;
      height: 47px;

      button {
        height: 59px;
        top: -12px;
      }
    }
  }
}

@mixin marker-sketch-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px;
  box-sizing: border-box;

  portal-marker-sketch {
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 320px;

      mat-mdc-form-field {
        width: 90%;
        margin-top: 16px;

        &.mat-mdc-form-field {
          font-size: 16px;
        }

        @include suffix-button();
        @include prefix-button();
      }
    }
  }
}

@mixin light-and-dark-color($element-selector, $host, $light-color, $dark-color) {
  @if $host {
    :host {
      #{$element-selector} {
        color: $light-color;
      }
    }

    portal-root.dark-theme :host {
      #{$element-selector} {
        color: $dark-color;
      }
    }
  } @else {
    #{$element-selector} {
      color: $light-color;
    }

    portal-root.dark-theme {
      #{$element-selector} {
        color: $dark-color;
      }
    }
  }
}

@mixin light-and-dark-background-color($element-selector, $host, $light-color, $dark-color) {
  @if $host {
    :host {
      #{$element-selector} {
        background-color: $light-color;
      }
    }

    portal-root.dark-theme :host {
      #{$element-selector} {
        background-color: $dark-color;
      }
    }
  } @else {
    #{$element-selector} {
      background-color: $light-color;
    }

    portal-root.dark-theme {
      #{$element-selector} {
        background-color: $dark-color;
      }
    }
  }
}

@mixin light-and-dark-host-background-color($light-color, $dark-color) {
  :host {
    background-color: $light-color;
  }

  portal-root.dark-theme :host {
    background-color: $dark-color;
  }
}

@mixin light-and-dark-border-color($element-selector, $host, $light-color, $dark-color) {
  @if $host {
    :host {
      #{$element-selector} {
        border-color: $light-color;
      }
    }

    portal-root.dark-theme :host {
      #{$element-selector} {
        border-color: $dark-color;
      }
    }
  } @else {
    #{$element-selector} {
      border-color: $light-color;
    }

    portal-root.dark-theme {
      #{$element-selector} {
        border-color: $dark-color;
      }
    }
  }
}

@mixin invert-icon-color($theme) {
  @if $theme == 'light' {
    --color3: #{variables.$light-text};
    --color2: #{variables.$dark-text};
  }
  @if $theme == 'dark' {
    --color2: #{variables.$light-text};
    --color3: #{variables.$dark-text};
  }
}
