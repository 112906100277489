/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzAwMGY0MCIsIj9lcjwjYjNiN2M2IiwiO2VyPCMwMDA4MmF$LCIlPmBePCMyNjE3ZGIiLCI~ZXI8I2JlYjlmNCIsIjtlcjwjMTcwZGNkfiwid2Fybj5gXjwjZmY3ZjQ4IiwiP2VyPCNmZmQ5YzgiLCI7ZXI8I2ZmNjIzMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZDlkYmUyIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMwMDBmNDB$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz5mYWxzZSwidmVyc2lvbj4xM30=
*/
@use 'sass:map';
@use 'node_modules/@angular/material' as mat;
@use 'variables';
@use 'palettes';
@use 'src/app/number-link/number-link-theme' as number-link;
@use 'src/app/document-position/document-position-quantity/document-position-quantity-theme' as document-position-quantity;
@use 'src/app/credits/credits-theme' as credits;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

$enthus-circular-regular-typography: mat.m2-define-typography-config(
  $font-family: 'Circular Regular',
);

$enthus-circular-bold-typography: mat.m2-define-typography-config(
  $font-family: 'Circular Bold',
  $button: mat.m2-define-typography-level(16px, 16px, 500),
);

@include mat.core();

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: palettes.$light-theme-palette,
      accent: palettes.$theme-accent-palette,
      warn: palettes.$theme-warn-palette,
    ),
    typography: $enthus-circular-regular-typography,
    density: 0,
  )
);

$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: palettes.$dark-theme-palette,
      accent: palettes.$theme-accent-palette,
      warn: palettes.$theme-warn-palette,
    ),
    typography: $enthus-circular-regular-typography,
    density: 0,
  )
);

$enthus-power-purple: (
  main: variables.$enthus-power-purple,
  lighter: variables.$enthus-power-purple,
  darker: variables.$enthus-power-purple,
  200: variables.$enthus-power-purple,
  // For slide toggle,
  contrast: (
      main: variables.$dark-text,
      lighter: variables.$light-text,
      darker: variables.$dark-text,
    ),
);
$enthus-power-purple-palette: mat.m2-define-palette($enthus-power-purple, main, lighter, darker);

$enthus-power-purple-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $enthus-power-purple-palette,
      accent: palettes.$theme-accent-palette,
    ),
    typography: $enthus-circular-regular-typography,
    density: 0,
  )
);

$tab-light-primary: (
  main: variables.$white-background,
  lighter: variables.$white-background,
  darker: variables.$white-background,
  200: variables.$white-background,
  // For slide toggle,
  contrast: (
      main: variables.$dark-text,
      lighter: variables.$dark-text,
      darker: variables.$dark-text,
    ),
);
$tab-light-theme-palette: mat.m2-define-palette($tab-light-primary, main, lighter, darker);

$tab-light-theme: map.deep-merge(
  $light-theme,
  (
    primary: $tab-light-theme-palette,
  )
);

$tab-dark-primary: (
  main: variables.$blue-background,
  lighter: variables.$blue-background,
  darker: variables.$blue-background,
  200: variables.$blue-background,
  // For slide toggle,
  contrast: (
      main: variables.$light-text,
      lighter: variables.$dark-text,
      darker: variables.$light-text,
    ),
);
$tab-dark-theme-palette: mat.m2-define-palette($tab-dark-primary, main, lighter, darker);

$tab-dark-theme: map.deep-merge(
  $dark-theme,
  (
    primary: $tab-dark-theme-palette,
  )
);

:root {
  --mdc-theme-surface: #{variables.$card-background-color-light};
  --mdc-theme-text-icon-on-background: #{variables.$dark-text};
  --mdc-theme-text-primary-on-background: #{variables.$dark-text};
  color: variables.$dark-text;
}

//region material themes
@include mat.all-component-themes($light-theme);
@include mat.all-component-typographies($enthus-circular-regular-typography);

@include mat.button-color(palettes.$light-theme-alternative-accent);
@include mat.button-typography($enthus-circular-bold-typography);
@include mat.button-toggle-typography($enthus-circular-bold-typography);

@include mat.toolbar-color($enthus-power-purple-theme);
@include mat.toolbar-typography($enthus-circular-bold-typography);

@include mat.slide-toggle-color($light-theme);

@include mat.typography-hierarchy($enthus-circular-regular-typography, '.e-contextmenu-wrapper');
@include mat.typography-hierarchy($enthus-circular-regular-typography, '.e-toast-container');
@include mat.typography-hierarchy($enthus-circular-bold-typography, '.e-toast-container .e-toast-message .e-toast-title');

@include mat.tabs-color($tab-light-theme);

//endregion

//region custom components themes
@include number-link.typography($enthus-circular-bold-typography);
@include document-position-quantity.color(variables.$document-position-quantity-input-color-light);
@include credits.color(palettes.$credits-light);
//endregion

.dark-theme,
body.dark-background portal-cart-position {
  --mdc-theme-surface: #{variables.$card-background-color-dark};
  --mdc-theme-text-icon-on-background: #{variables.$light-text};
  --mdc-theme-text-primary-on-background: #{variables.$light-text};
  color: variables.$light-text !important;

  //region material themes
  @include mat.all-component-colors($dark-theme);
  @include mat.button-color(palettes.$dark-theme-alternative-accent);
  @include mat.toolbar-color($enthus-power-purple-theme);
  @include mat.slide-toggle-color($dark-theme);
  @include mat.tabs-color($tab-dark-theme);
  //endregion material themes

  //region custom components themes
  @include document-position-quantity.color(variables.$document-position-quantity-input-color-dark);
  @include credits.color(palettes.$credits-dark);
  //endregion custom components themes
}
