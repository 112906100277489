@use 'variables';

body.dark-background,
body.light-background {
  & .e-grid {
    border: unset;

    & .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
      font-size: 12px;
      line-height: 18px;
    }

    .e-headercell {
      height: 48px;

      .e-headertext {
        font-size: 14px;
        line-height: 32px;
      }
    }

    .e-rowcell {
      border-top: unset;
      border-bottom: unset;
      padding-top: 4px;
      padding-bottom: 4px;
      color: inherit !important;
      font-size: 14px;
      line-height: 40px;

      mat-icon {
        vertical-align: middle;
      }
    }

    .e-summarycell {
      border-bottom: transparent;
    }
  }

  .e-pager {
    border-top: transparent;
  }
}

// Colors
body.light-background {
  .e-grid {
    .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
      color: #343434;
    }

    .e-row:not(.e-altrow) {
      background-color: #f8f8f8;
    }

    .e-headercell {
      border-color: #d9dbe2;

      .e-headertext {
        color: #858585;
      }
    }

    .e-rowcell {
      border-color: #d9dbe2;
    }

    .e-groupdroparea,
    .e-groupdroparea.e-grouped {
      background-color: #001969;
      color: #ffffff;
    }

    .e-groupdroparea.e-hover {
      background-color: #071f85;
    }
  }

  .e-pager .e-currentitem {
    background-color: #000f40;

    &:hover {
      background-color: lighten(#000f40, 20%);
    }
  }
}

body.dark-background {
  .e-popup,
  .e-ddl.e-popup,
  .e-grid-menu {
    background-color: #263567;
    border-color: #000f40;

    .e-dropdownbase .e-list-item,
    .e-dropdownbase .e-list-item.e-item-focus,
    .e-contextmenu,
    .e-menu-parent,
    .e-menu-item,
    .e-dlg-content,
    .e-footer-content,
    .e-calendar,
    .e-footer-container {
      background-color: #263567;
    }

    .e-dropdownbase .e-list-item.e-item-focus,
    .e-dropdownbase .e-list-item.e-active,
    .e-dropdownbase .e-list-item.e-active.e-hover,
    .e-dropdownbase .e-list-item.e-hover {
      background-color: #ffffff;
      color: #000f40;
    }
  }

  .e-grid {
    .e-groupcaption,
    .e-indentcell,
    .e-recordplusexpand,
    .e-recordpluscollapse,
    .e-cloneproperties,
    .e-groupdroparea,
    .e-groupdroparea.e-grouped {
      background-color: #000f40;
      border-color: #263567;
    }

    &.e-gridhover
      .e-row:not(.e-disable-gridhover):not(.e-editedrow):not(.e-detailrow):hover
      .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell) {
      background-color: #ffffff2e;
    }

    .e-groupdroparea.e-hover {
      background-color: #263567;
    }

    .e-gridheader,
    .e-toolbar,
    .e-headercontent,
    .e-gridcontent {
      border-left: 1px solid #000f40;
      border-right: 1px solid #000f40;
    }

    .e-gridheader {
      border-top-color: #263567;
      border-bottom-color: transparent;
    }

    .e-gridheader,
    .e-headercell,
    .e-toolbar,
    .e-grouptopleftcell,
    .e-table,
    .e-pager,
    .e-pager .e-pagercontainer,
    .e-pager .e-numericitem,
    .e-pager .e-nextpage,
    .e-pager .e-prevpage,
    .e-pager .e-nextpagedisabled,
    .e-pager .e-prevpagedisabled,
    .e-pager .e-lastpagedisabled,
    .e-pager .e-firstpagedisabled,
    .e-content {
      background-color: #1a2753;
    }

    .e-toolbar .e-toolbar-items {
      background-color: transparent;

      .e-toolbar-item {
        &:hover {
          background-color: #263567;
        }

        &.e-overlay {
          background-color: transparent;
        }

        .e-tbar-btn.e-btn {
          background-color: transparent;
        }
      }
    }

    .e-row:not(.e-altrow),
    .e-summaryrow .e-summarycell,
    .e-groupheadercell,
    .e-gridfooter {
      background-color: #263567;
    }

    .e-headercell {
      border-color: #000f40;

      .e-headertext {
        color: #858585;
      }
    }

    .e-rowcell {
      border-color: #000f40;
    }

    .e-summaryrow .e-summarycell {
      border-color: #000f40;
    }
  }

  & .e-pager .e-currentitem.e-numericitem.e-active {
    background-color: #ffffff;

    &:hover {
      background-color: darken(#ffffff, 20%);
    }
  }
}

.e-toast-container .e-toast .e-toast-actions .e-btn.e-btn-link {
  color: variables.$link-color-light !important;
  font-family: unset;
  font-weight: 900;
}

.e-toast-container .e-toast-success {
  background-color: variables.$enthus-success !important;
}

.e-toast-container .e-toast.e-toast-danger {
  background-color: variables.$enthus-error !important;
}

.e-toast-container .e-toast.e-toast-info {
  background-color: variables.$enthus-info !important;
}

.e-toast-container .e-toast.e-toast-warning {
  background-color: variables.$enthus-warn !important;
}

.e-toast-container .e-toast-message .e-toast-title,
.e-toast-container .e-toast-message .e-toast-content {
  color: variables.$dark-text !important;
}
