@use 'variables';
@use 'sass:map';
@use 'node_modules/@angular/material' as mat;

// TODO: use themes and overwrite only the necessary stuff

.mat-mdc-chip-remove,
.mat-mdc-button-base {
  & > .mat-icon {
    min-width: 24px !important;
    height: 24px !important;
    font-size: 24px !important;
  }
}

.mat-mdc-button-base:not([class*='mat-calendar-']):not(.mat-mini-fab) {
  height: 48px;
  align-items: center;

  &.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
    opacity: 0;
  }

  &:not([mat-fab]):not([mat-mini-fab]) {
    border-radius: 0 !important;

    .mat-mdc-button-persistent-ripple {
      border-radius: 0 !important;
    }
  }

  &.mat-mdc-raised-button:not([disabled='true']) {
    border: solid 2px transparent;
  }

  &.mdc-button--outlined {
    border-width: 2px;
    transition: unset;

    &.mat-warn:not([disabled='true']) {
      border-color: variables.$enthus-error !important;

      &:hover {
        background-color: variables.$hover-accent-button-background-light;
      }
    }
  }

  &.mat-mdc-raised-button {
    &.mat-primary {
      &:not(.mat-button-disabled) {
        &:hover {
          background-color: variables.$hover-primary-button-background-light;
        }

        --color3: variables.$light-text;
      }
    }

    &.mat-accent {
      &:not(.mat-button-disabled) {
        border-color: variables.$dark-text;

        &:hover {
          background-color: variables.$hover-accent-button-background-light;
        }
      }
    }

    &[disabled='true'] {
      background-color: variables.$disabled-primary-button-background-light;
      --color3: variables.$light-disabled-text;
    }
  }

  &.mdc-button--outlined {
    &.mat-primary:not([disabled='true']) {
      border-color: variables.$dark-text !important;
    }

    &.mat-accent:not([disabled='true']) {
      border-color: variables.$light-text !important;
    }

    &[disabled='true'] {
      --color3: variables.$light-disabled-text;
    }
  }

  &.thin {
    height: 40px;
  }

  &.mat-button-toggle-button:not([disabled='true']):hover {
    background-color: variables.$hover-primary-button-background-light;
  }

  &:focus {
    border-color: variables.$focus-button-color !important;
  }

  &.mat-warn:not([disabled='true']) {
    --color3: variables.$enthus-error;
  }
}

.mat-mdc-fab,
.mat-mdc-mini-fab,
.mat-mdc-icon-button {
  display: inline-flex !important;
  justify-content: center !important;

  & > span:not(.mat-badge-content) {
    display: inline-flex !important;
  }
}

.dark-theme {
  .mat-mdc-button-base:not([class*='mat-calendar-']) {
    &.mdc-button--raised {
      &.mat-primary {
        &:not([disabled='true']) {
          &:hover {
            background-color: variables.$hover-primary-button-background-dark;
          }

          --color3: variables.$dark-text;
        }
      }

      &.mat-accent {
        &:not([disabled='true']) {
          border-color: variables.$light-text !important;

          &:hover {
            background-color: variables.$hover-accent-button-background-dark;
          }
        }
      }

      &[disabled='true'] {
        background-color: variables.$disabled-primary-button-background-dark;
      }
    }

    &.mdc-button--outlined {
      &.mat-primary:not([disabled='true']) {
        border-color: variables.$light-text !important;
      }

      &.mat-accent:not([disabled='true']) {
        border-color: variables.$dark-text !important;
      }

      &.mat-warn:not([disabled='true']) {
        &:hover {
          background-color: variables.$hover-accent-button-background-dark;
        }
      }
    }

    &.mat-button-toggle-button:not([disabled='true']):hover {
      background-color: variables.$hover-primary-button-background-dark;
    }
  }

  .mat-mdc-button-base {
    &.mat-mdc-icon-button {
      &:not([disabled='true']):hover {
        color: variables.$hover-primary-button-background-dark !important;
        --color3: variables.$hover-primary-button-background-dark;
      }

      &[disabled='true'] {
        color: variables.$light-disabled-text !important;
        --color3: variables.$light-disabled-text;
      }
    }
  }
}
