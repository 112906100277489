mat-icon svg {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.enthus-name {
  font-size: 32px;
  color: red;
}
========================================== */

.enthus-credits {
  width: 1.388671875em;
}

.enthus-password {
  width: 0.9599609375em;
}
