@use 'mixins';

.show-more-list-view {
  margin: 10px 2px 10px 0;
  user-select: text;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  mat-card-content {
    margin-bottom: 0;

    div.row:last-of-type {
      line-height: 28px; // TODO: Fix effect on new base list view element rows
    }

    div.row {
      display: flex;
      justify-content: space-between;
      line-height: 20px;
      padding: 6px 0;
      align-items: center;

      span.description {
        font-weight: 300;
        margin-right: 5px;
        min-width: 150px;
      }

      span.value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      div.location-wrapper-mobile {
        display: flex;
        align-items: center;
        overflow: hidden;
        height: 28px;
        width: 100%;
        justify-content: flex-end;

        div.location {
          overflow: hidden;
          margin-left: 5px;

          p {
            margin: unset;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        form {
          display: inline-block;
          padding-right: unset;
          width: 100%;
          overflow: hidden;
          height: 28px;

          mat-form-field {
            padding: unset;
            text-align: right;
            width: 100%;
            height: 28px;

            ::ng-deep div.mat-form-field-infix {
              width: 100%;
              border-top: unset;
            }
          }
        }

        div.edit-icon-wrapper-mobile {
          margin-left: 5px;
          min-width: 24px;
          @include mixins.no-text-selection;

          mat-icon {
            vertical-align: middle;
          }
        }

        div.show {
          opacity: 1;
          transition: 300ms;
        }

        div.hide {
          opacity: 0;
          transition: 300ms;
          pointer-events: none;
        }
      }
    }
  }
}
