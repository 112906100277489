@use 'variables';

.mat-mdc-selection-list,
.mat-mdc-list {
  &.mat-mdc-list-base.mdc-list {
    --mdc-list-list-item-leading-icon-color: #{variables.$dark-text};
    --mdc-list-list-item-leading-avatar-color: #{variables.$enthus-smart-yellow};
    --mdc-list-list-item-hover-leading-icon-color: #{variables.$dark-text};
    --mdc-list-list-item-label-text-color: #{variables.$dark-text};
    --mdc-list-list-item-supporting-text-color: #{variables.$dark-text};
    --mdc-theme-text-hint-on-background: #{variables.$dark-text};
    --mdc-theme-text-secondary-on-background: #{variables.$dark-text};

    &.has-trailing-button {
      .mdc-list-item__content {
        margin-right: 60px;
      }
    }
  }
}

.dark-theme {
  .mat-mdc-selection-list,
  .mat-mdc-list {
    &.mat-mdc-list-base.mdc-list {
      --mdc-list-list-item-leading-icon-color: #{variables.$light-text};
      --mdc-list-list-item-leading-avatar-color: #{variables.$enthus-power-purple};
      --mdc-list-list-item-hover-leading-icon-color: #{variables.$light-text};
      --mdc-list-list-item-label-text-color: #{variables.$light-text};
      --mdc-list-list-item-supporting-text-color: #{variables.$light-text};
      --mdc-theme-text-hint-on-background: #{variables.$light-text};
      --mdc-theme-text-secondary-on-background: #{variables.$light-text};
    }
  }
}
