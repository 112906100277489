@use 'sass:map';
@use 'node_modules/@angular/material' as mat;

@mixin typography($config-or-theme) {
  portal-number-link {
    a {
      font-family: mat.m2-font-family($config-or-theme);
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
