@use 'node_modules/@angular/material' as mat;
@use 'sass:color';
@use 'sass:map';
@use 'variables';
@use 'mixins';

body.dark-background {
  background-color: variables.$dark-background !important;
}

portal-root.dark-theme {
  // TODO decide if we need to invert these colors or not
  //region icomoon icons color overwrite
  --color2: #{variables.$dark-text};
  --color3: #{variables.$light-text};
  //end region

  mat-icon.invert-color {
    @include mixins.invert-icon-color('dark');
  }

  ejs-grid {
    table tr:last-child {
      > td {
        border-bottom-width: 1px !important;
      }

      &.e-emptyrow > td {
        border-bottom-color: #616161;
      }
    }
  }

  .e-grid {
    td.e-active {
      background: #cecece2e !important;
    }
  }

  .position-card {
    .position-card-header {
      .position-card-subtitle {
        color: rgba(255, 255, 255, 0.7) !important;
      }
    }
  }

  .asset-mobile-card-container {
    .asset-mobile-card {
      .asset-mobile-card-header {
        .asset-mobile-card-subtitle {
          color: rgba(255, 255, 255, 0.7) !important;
        }
      }
    }
  }

  .asset-desktop-card {
    .asset-desktop-card-header {
      .asset-desktop-card-subtitle {
        color: rgba(255, 255, 255, 0.7) !important;
      }
    }
  }

  .document-card {
    div.column-row .value {
      color: rgba(255, 255, 255, 0.7) !important;
    }

    .document-header-shipments {
      span.no-tracking-available {
        color: rgba(255, 255, 255, 0.7) !important;
      }
    }
  }

  mat-sidenav {
    color: white !important;

    .mat-drawer-inner-container {
      mat-nav-list {
        .navigation-divider-text {
          color: rgba(255, 255, 255, 0.7) !important;
        }
      }
    }
  }

  mat-sidenav-container {
    .mat-drawer-backdrop.mat-drawer-shown {
      background-color: darken(transparentize(map.get(variables.$mat-dark-theme-background, card), 0.4), 20%);
    }
  }

  .mat-mdc-selection-list {
    .mat-mdc-list-option {
      div.indicators {
        mat-icon {
          padding-left: 6px;
        }
      }

      &.mat-list-item-disabled {
        p {
          color: variables.$light-disabled-text !important;
        }
      }
    }
  }

  .purchase-input-form-field {
    &.not-clickable {
      input {
        pointer-events: none !important;
      }
    }
  }

  .drag-handle {
    background-color: darken(mat.m2-get-color-from-palette(variables.$mat-dark-theme-background, card), 10%) !important;
  }

  .add-comment-section {
    background: mat.m2-get-color-from-palette(mat.$m2-dark-theme-background-palette, card) !important;
  }

  portal-cart-position {
    .position-item-no {
      color: darken(variables.$light-text, 30%) !important;
    }
  }

  portal-getting-started {
    mat-horizontal-stepper {
      background-color: variables.$blue-background !important;

      .mat-horizontal-content-container {
        .mat-button-toggle-appearance-standard {
          background-color: variables.$blue-background;
        }

        .skip-button {
          color: darken(variables.$light-text, 30%) !important;
        }
      }
    }
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: variables.$light-text;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: variables.$light-text;
  }

  portal-media-overlay {
    .close-overlay-button-wrapper {
      button {
        color: variables.$light-text !important;
      }
    }

    .large-preview {
      background-color: transparentize(map.get(variables.$mat-dark-theme-background, card), 0.4) !important;
    }

    .overlay-mobile-container {
      background-color: map.get(variables.$mat-dark-theme-background, card) !important;
    }
  }

  .cdk-overlay-container {
    .blur-backdrop {
      background-color: darken(transparentize(map.get(variables.$mat-dark-theme-background, card), 0.2), 20%);
    }
  }

  div.visual-state-indicator {
    mat-icon {
      background-color: variables.$card-background-color-dark !important;
      border-color: variables.$blue-background !important;
    }

    .current-state {
      mat-icon {
        background-color: variables.$light-text !important;
        color: variables.$dark-text !important;
        --color3: variables.$dark-text !important;
      }
    }
  }

  div.states {
    div.state-row {
      &:not(.previous) .left-column mat-icon {
        color: variables.$blue-sub-text-color !important;
      }

      .center-column,
      .right-column {
        color: transparentize(variables.$light-text, 0.2) !important;
      }

      &.active,
      &.previous {
        .center-column,
        .right-column {
          color: variables.$light-text !important;
        }
      }

      &.active .left-column mat-icon {
        color: variables.$enthus-info !important;
      }

      &.pending-approval {
        .left-column mat-icon {
          color: variables.$enthus-error !important;

          &::after {
            background-color: variables.$enthus-error !important;
          }
        }
      }
    }
  }

  portal-deactivated-page {
    .contact-information {
      a {
        color: variables.$light-text !important;
      }
    }
  }

  mat-icon.mat-list-avatar {
    @include mixins.invert-icon-color('dark');
  }
}
