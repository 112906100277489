@use 'variables';

.mat-mdc-tab-group.mat-background-primary {
  --mat-tab-header-with-background-background-color: #{variables.$white-background};
  --mat-tab-header-with-background-foreground-color: #{variables.$dark-text};
}

.dark-theme .mat-mdc-tab-group.mat-background-primary {
  --mat-tab-header-with-background-background-color: #{variables.$blue-background};
  --mat-tab-header-with-background-foreground-color: #{variables.$light-text};
}
