mat-toolbar#navigationToolbar {
  height: 48px;
  padding: 0px 7px 0px 3px;

  > div#logo {
    height: 30px;
    width: 102px;
  }

  > button,
  .hamburger-container {
    transform: scale(0.75);
  }
}

portal-documents,
portal-assets {
  mat-form-field.search {
    height: 48px;
    padding: 6px 16px 0px 16px;
  }
}
