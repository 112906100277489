@use 'node_modules/@angular/material' as mat;
@use 'variables';
@use 'sass:map';

.cdk-overlay-container {
  .blur-backdrop {
    backdrop-filter: blur(4px);
    background-color: darken(transparentize(map.get(variables.$mat-light-theme-background, card), 0.2), 15%);
  }
}

portal-root.dark-theme {
  .cdk-overlay-container {
    .blur-backdrop {
      background-color: transparentize(map.get(variables.$mat-dark-theme-background, card), 0.2);
    }
  }
}

.cdk-overlay-pane {
  &.mobile {
    max-width: unset !important;
    width: 100vw !important;
    height: 100vh !important;

    mat-dialog-container {
      width: 100%;
      height: 100%;
      border-radius: unset;
    }
  }

  &.click-through {
    pointer-events: none !important;
  }

  .item-autocomplete {
    max-width: 100vw;
    min-height: 116px;
    max-height: calc(100vh - 200px);
    right: 200px;

    &.mobile {
      width: 100vw;
      position: fixed;
      left: 0;
      top: 64px;
    }

    mat-option {
      line-height: unset;
      height: 100px;
      padding: unset;

      mat-card {
        margin: unset;

        mat-list-item {
          height: 100px !important;

          .mat-list-item-content {
            div.first-row {
              height: 100%;
            }
          }
        }
      }
    }
  }

  .alternative-autocomplete {
    max-width: 100vw;
    min-height: 116px;
    max-height: calc(100vh - 200px);
    right: 200px;

    &.mobile {
      width: 100vw;
      position: fixed;
      left: 0;
      top: 64px;
    }
  }

  &.division-factor-info-overlay {
    portal-base-overlay {
      .base-overlay-container {
        @include mat.elevation(3);

        mat-toolbar {
          background-color: variables.$enthus-error;
        }

        .base-overlay-content {
          padding: 20px;
        }
      }
    }
  }
}
