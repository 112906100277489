@use 'variables';
@use 'palettes';

:not(portal-show-more) .mat-mdc-selection-list {
  background-color: variables.$white-background;

  &.portal-list {
    overflow-y: auto;
  }
}

.mat-mdc-selection-list {
  .mat-divider {
    right: 60px !important;
    left: 60px !important;
    bottom: 0 !important;
    position: absolute;
  }

  .mat-mdc-list-item-unscoped-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    bottom: 0;
    padding-right: 10px;
    box-sizing: border-box;
  }

  padding-top: unset !important;
  height: 100%;

  .mat-mdc-list-option {
    transition: all 150ms ease-in-out;

    &:last-of-type .mat-divider {
      display: none;
    }

    .mdc-list-item__content {
      transition: margin-left 150ms ease-in-out;

      .mat-mdc-list-item-line::before {
        display: none;
      }

      p {
        margin: 0;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &.mdc-list-item--selected {
      background: variables.$enthus-power-purple !important;
      border-left: 3px solid variables.$dark-text !important;
    }

    .mat-mdc-list-item-avatar {
      text-align: center;
      line-height: 40px;
      background-color: variables.$enthus-smart-yellow;
      color: variables.$dark-text;
      align-self: center;
      margin: unset 16px;
    }
  }
}

.dark-theme {
  :not(portal-show-more) .mat-mdc-selection-list {
    background-color: variables.$blue-background !important;

    .mat-mdc-list-option {
      &.mdc-list-item--selected {
        background-color: desaturate(lighten(variables.$blue-background, 15%), 15%) !important;
        border-left: 3px solid variables.$light-text !important;
      }

      .mat-mdc-list-item-avatar {
        background-color: variables.$enthus-power-purple !important;
        color: variables.$dark-text;

        --color3: #{variables.$dark-text};
        --color2: #{variables.$light-text};
      }
    }
  }
}
