@use 'variables';
@use 'mixins';

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.light-theme .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08 !important;
}

.light-theme .grey-form-field-background {
  .mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
    background-color: variables.$grey-input-background !important;
  }
}

@include mixins.light-and-dark-background-color(
  '.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper',
  false,
  variables.$white-input-background,
  variables.$blue-input-background-dark
);
