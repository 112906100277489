@use 'sass:map';
@use 'variables';

* {
  // FF Scrollbar Style
  scrollbar-color: variables.$scrollbar-thumb map.get(variables.$mat-light-theme-background, background);
  scrollbar-width: thin;

  // Edge Scrollbar Style
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  height: 6px;
  width: 4px;
}

::-webkit-scrollbar {
  background: map.get(variables.$mat-light-theme-background, background);
}

::-webkit-scrollbar-thumb {
  background: variables.$scrollbar-thumb;
}

.mat-mdc-form-field textarea {
  &::-webkit-scrollbar {
    width: 17px;
    background: unset !important;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border-left: 13px solid transparent !important;
    background-clip: padding-box !important;
  }

  &::-webkit-scrollbar-track {
    //background: $portal-shop-background-color-light; // TODO check wich color shall be here for textareas
  }

  &::-webkit-resizer {
    background-image: url('../icons/resize-handle.png');
    background-size: contain;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.dark-theme {
  * {
    // FF Scrollbar Style
    scrollbar-color: variables.$scrollbar-thumb map.get(variables.$mat-dark-theme-background, background) !important;
  }

  ::-webkit-scrollbar {
    background: map.get(variables.$mat-dark-theme-background, background) !important;
  }

  .mat-mdc-form-field textarea {
    &::-webkit-scrollbar-track {
      border-left: 13px solid transparent !important;
    }
  }
}
