@use 'variables';

mat-checkbox.mat-checkbox-checked {
  .mat-checkbox-layout .mat-checkbox-inner-container {
    .mat-checkbox-background .mat-checkbox-checkmark-path {
      stroke: variables.$enthus-smart-yellow !important;
    }
  }
}

.dark-theme {
  mat-checkbox.mat-checkbox-checked {
    .mat-checkbox-layout .mat-checkbox-inner-container {
      .mat-checkbox-background .mat-checkbox-checkmark-path {
        stroke: variables.$dark-text !important;
      }
    }
  }
}
