@use 'variables';
@use 'mixins';

.mat-drawer-container .mat-mdc-list-item-avatar {
  color: variables.$dark-text;
}

.mat-drawer-container,
.mat-drawer {
  --mat-sidenav-content-text-color: #{variables.$dark-text};
}

.dark-theme {
  .mat-drawer-container,
  .mat-drawer {
    --mat-sidenav-content-text-color: #{variables.$light-text};
  }
}
