@use 'sass:map';
@use 'variables';

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: #{variables.$white-background};
  --mdc-dialog-container-shape: 0;
  --mdc-dialog-supporting-text-color: #{variables.$dark-text};

  .mdc-dialog__title {
    font-family: 'Circular Bold', serif !important;
  }

  .mdc-dialog__surface {
    border-radius: var(--mdc-dialog-container-shape);
  }
}

.dark-theme .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #{variables.$blue-background};
  --mdc-dialog-supporting-text-color: #{variables.$light-text};
}
