@use 'variables';
@use 'mixins';

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #{variables.$light-background} !important;
}

.dark-theme {
  .mat-mdc-standard-chip {
    --mdc-chip-elevated-container-color: #{variables.$dark-background} !important;
  }
}

.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected {
  --mdc-chip-label-text-color: #{variables.$light-background} !important;
  --mdc-chip-selected-label-text-color: #{variables.$light-background} !important;
  --mat-chip-selected-trailing-icon-color: #{variables.$light-background} !important;
  --mdc-chip-with-icon-selected-icon-color: #{variables.$light-background} !important;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #{variables.$light-background} !important;
}
