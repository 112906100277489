@use 'sass:map';

@mixin color($theme-or-config) {
  $default-background: map.get($theme-or-config, 'default');
  $accessory-background: map.get($theme-or-config, 'accessory');
  $cart-background: map.get($theme-or-config, 'cart');
  $mobile-background: map.get($theme-or-config, 'mobile');

  portal-document-position-quantity {
    .purchase-input-form-field {
      &:not(.not-clickable) {
        .mat-mdc-text-field-wrapper {
          background-color: $default-background !important;
        }

        &.is-accessory {
          .mat-mdc-text-field-wrapper {
            background-color: $accessory-background !important;
          }
        }

        &.is-cart-position {
          .mat-mdc-text-field-wrapper {
            background-color: $cart-background !important;
          }
        }

        &.mobile {
          .mat-mdc-text-field-wrapper {
            background-color: $mobile-background !important;
          }
        }
      }
    }
  }
}
